import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import Spacing from '../../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'
import EventRegistrationForm from '../../../components/EventRegistrationForm'

const EventRegistrationMunich = () => {
    const data = useStaticQuery(graphql`
        query eventRegistrationMunichQuery {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "EventRegistrationMunich" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Spacing />
            <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '100%' }}>
                    <section className='EventRegistration--heroMunich'>
                        <div className='container-1 EventRegistration--hero__content'>
                            <h1 className='cera-pro'>
                                Real-time insights on tap with <br className='onlyDesktop' /> Apache
                                Kafka and Druid
                            </h1>
                            <h2 className='cera-pro'>Featuring Imply + more</h2>
                            <h3 className='roboto EventRegistration--hero__h3-primary'>
                                Thursday 10th October | 5-8pm
                            </h3>
                            <h3 className='roboto EventRegistration--hero__h3-secondary'>
                                Celonis SE Theresienstraße 6,
                                <br /> 80333 München
                            </h3>
                        </div>
                    </section>
                    <section className='EventRegistration--under-hero'>
                        <div className='container-1'>
                            <p>
                                Join fellow developers, engineers, and data enthusiasts for this
                                special Apache Kafka and Druid Meetup where we’ll explore and
                                analyze streaming data from a brewery.
                            </p>
                            <ul>
                                <li>5:30pm-6:00pm: Welcome and Networking</li>
                                <li>
                                    6:00pm-6:30pm: Fast aggregation and insights on smart brewery
                                    data using Kafka and Druid
                                </li>
                                <li>6:30pm-7:30pm: Hands-on workshop to explore streaming data</li>
                                <li>7:30pm-8:00pm: Thinkport Talk</li>
                                <li>8:00pm + : Food, Drinks and Networking</li>
                            </ul>
                        </div>
                    </section>
                    <div className='onlyMobile'>
                        <div className='EventRegistration--form'>
                            <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkt' />
                        </div>
                    </div>
                    <Spacing />
                    <section className='roboto EventRegistration--feature'>
                        <div className='container-1'>
                            <h4 className='EventRegistration--feature__company cera-pro'>
                                Sessions
                            </h4>
                            <h1 className='EventRegistration--feature__title cera-pro'>
                                Fast aggregation and insights on smart{' '}
                                <br className='onlyDesktop' /> brewery data using Kafka and Druid
                            </h1>
                            <p>
                                Have you ever wondered how a modern event analytics pipeline is
                                different from a classical ETL setup? We are going to explain event
                                analytics using Apache Druid, the real time OLAP engine. Then, we’ll
                                show you how to design and set up an analytics pipeline in just a
                                few minutes, using Kafka for delivery, SQL for pre-processing, and
                                Druid for real-time OLAP.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    Speaker:
                                </span>{' '}
                                Hellmar Becker is a Senior Sales Engineer at Imply. He has worked in
                                data analytics for more than 20 years in various pre and post-sales
                                roles. Hellmar has worked with large customers in the finance, telco
                                and retail industries, and spent several years at big data company,
                                Hortonworks, and at Confluent.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    Speaker:
                                </span>{' '}
                                Adam Noonan is the Lead Solutions Engineer at Lenses.io. A reformed
                                developer, Adam has enjoyed working with government, defense, and
                                Fortune 100 customers on big data, AI/ML, and data streaming
                                strategies at NetApp, Celonis, and now Lenses.io.
                            </p>
                        </div>
                    </section>
                    <Spacing />
                </div>
                <div style={{ position: 'sticky', right: '20px' }} className='onlyDesktop'>
                    <div className='EventRegistration--form'>
                        <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkt' />
                    </div>
                </div>
            </div>
            <Spacing />
            <section className={' mt-5 mt-sm-0'}>
                <div className='sectionWithGuyBlackStrip' style={{ paddingBottom: '50px' }}>
                    <div className='d-flex flex-wrap align-items-bottom'>
                        <div
                            className='container-1 col-12 text-left mt-3 mb-4'
                            style={{ maxHeight: '170px', paddingLeft: 0 }}>
                            <h2 className='f-40 lh-52 pb-0 text-white'>
                                Build better, sleep easy <br className={'onlyDesktop'} />
                                with the developer experience <br className={'onlyDesktop'} /> for
                                any Kafka
                            </h2>
                            <div className='roll-button navbar-dl-button heroLottieButton'>
                                <a
                                    id='navbar-download'
                                    style={{ fontSize: '1rem' }}
                                    className='text-white mr-0 bg-red'
                                    href='/start/'>
                                    <span data-hover='Start free'>Start free</span>
                                </a>
                            </div>
                        </div>
                        <StaticImage
                            className='pt-2 w-35 sectionWithGuyImage'
                            style={{ left: '50%' }}
                            src='../resources/images/new_homepage/developer-experience.webp'
                            placeholder='tracedSVG'
                            alt='Security'
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default EventRegistrationMunich
